import Card from "../../../../entity/singel-card/components/card-apartment";
import './style.scss';
import {forwardRef, useEffect, useRef, useState} from "react";
import Tabs from "../../../../entity/singel-card/components/tabs";
import Scheme from "../Scheme";
import Loader from "../../../../entity/main-page/components/loader";
import {useParams} from "react-router-dom";
import {getObjectLayout, getOffersCard} from "../../../../shared/api/endpoints";

const tabs = [
    {name: 'Список'},
    {name: 'Схема'},
];
const sortOption = [
    {value: 0, name: 'По цене, сначала дешевле'},
    {value: 1, name: 'По цене, сначала дороже'},
]


const Apartments = forwardRef(({
                                   schemeCards,
                                   setFilteredCards,
                                   cards,
                                   loadMore,
                                   loading,
                                   dataLayout
                               }: any, ref: any) => {
    const [activeTab, setActiveTab] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<number>(0);
    const [visibleCards, setVisibleCards] = useState<any[]>([]);
    const [visibleCount, setVisibleCount] = useState(20);
    const CARDS_PER_PAGE = 20;
    const {id} = useParams();
    useEffect(() => {
        loadMore(1);
    }, []);


    useEffect(() => {
        setVisibleCards(cards.slice(0, visibleCount));
    }, [cards, visibleCount]);

    const handleOptionClick = async (value: any) => {
        setSelected(value);
        setIsOpen(false);
        await sortHandler(value);
    };
    const handleLoadMore = () => {
        setVisibleCount((prev) => prev + CARDS_PER_PAGE)
    };

    const sortHandler = async (value: any) => {
        try {
            let page = 1;
            let nextPage = page;
            let results: any = [];

            while (true) {
                const data = await getOffersCard(id, nextPage, value);
                results = [...results, ...data.results];

                if (!data.next) break;

                nextPage += 1;
            }
            setFilteredCards(results.map((item: any) => item['layout_fields']).map((group: any, groupIndex: number) =>
                group?.reduce((acc: any, item: any) => {
                    const parentId = results[groupIndex]?.id;
                    acc[item.source] = item.data?.value || null;
                    acc['id'] = parentId;
                    return acc;
                }, {})));
        } catch (error) {
            console.error("Ошибка при получении предложений:", error);
        }
    };

    return <div className="apartments" ref={ref}>
        <Tabs tabs={tabs} setActive={setActiveTab} activeTab={activeTab}/>
        <div className="apartments__info">
            <h4 className="apartments__search">Найдено {cards.length} предложений</h4>
            <div className="apartments__sort">
                <div
                    className="form-label-select-content"
                    id="districtSelect"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selected ? sortOption.find((option) => option.value == selected)?.name : 'По цене, сначала дешевле'}
                </div>
                {isOpen && (
                    <ul className="filters__list active">
                        {sortOption.map((option, index) => (
                            <li
                                key={index}
                                className={`${selected == option.value ? "selected" : ""}`}
                                onClick={() => handleOptionClick(option.value)}
                            >
                                <button className={'filters__mobile-top'}>{option.name}</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
        {loading && <Loader/>}
        {activeTab === 0 ? <>
            <div className="single__items">
                {visibleCards.map((item: any, ind: number, arr?: any) => (
                    <Card key={ind} {...item} dataArr={arr} dataLayout={dataLayout}/>
                ))}
            </div>
            {visibleCards.length < cards.length && (
                <div className="comfort__button-full apartment__btn-more">
                    <button onClick={handleLoadMore} disabled={loading}>
                        {loading ? <Loader/> : "показать ещё"}
                    </button>
                </div>
            )}
        </> : <Scheme dataScheme={schemeCards}/>}
    </div>
})

export default Apartments;