import Card, {calculatePricePerSquareMeter} from '../../entity/singel-card/components/card-apartment'
import {typeObject} from '../single'
import Banner from '../single/components/Banner'
import FormSign from '../single/components/FormSign'
import Specialist from '../single/components/Specialist'
import FeedBack from './modules/feedback/feedback'
import SimilarOffer from './modules/similar-offers/similar-offers'
import {useEffect, useState} from "react";

import SingleInfo from "../single/components/SingleInfo";
import Description from "../single/components/Description";
import ActionButton from "../../entity/singel-card/components/action-button";
import FavoriteButtonIcon from "../../shared/assets/icons/favorite-button-icon";
import CompareButtonIcon from "../../shared/assets/icons/compare-button-icon";
import Attributes from "../../entity/object/attributes";
import Maps from "./components/map";
import {useParams} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import SliderButton from "./ui/buttons/slider-button/slider-button";
import {getCardsObjectLayout, getObjectLayout} from "../../shared/api/endpoints";
import Loader from "../../entity/main-page/components/loader";

const CurrentApartment = () => {
    const {width} = useWindowDimensions();
    const [cards, setCards] = useState<any>([]);
    const [offers, setOffers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [shareText, setShareText] = useState('Поделиться');
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    const [visibleCards, setVisibleCards] = useState<any[]>([]);
    const CARDS_PER_PAGE = 20;
    const [visibleCount, setVisibleCount] = useState(CARDS_PER_PAGE);


    useEffect(() => {
        setVisibleCards(offers.slice(0, visibleCount));
    }, [offers, visibleCount]);

    const handleLoadMore = () => {
        setVisibleCount((prev) => prev + CARDS_PER_PAGE)
    };

    useEffect(() => {
        if (isLoading) return;
        setIsLoading(true);
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const data = await getCardsObjectLayout(id);
                setCards(data.results);
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
        fetchData()
    }, [id]);

    const getFieldValue = (source: string, isTextValue = false) => {
        const dataField = !isTextValue ? cards[0]?.layout_fields?.find((item: typeObject) => item.source === source)?.data?.value
            : cards[0]?.layout_fields?.find((item: typeObject) => item.source === source)?.data?.text_value;
        return dataField;
    };

    const shareHandler = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            setShareText('Скопировано!');
        } catch (error) {
            setShareText('Ошибка копирования');
        } finally {
            setTimeout(() => setShareText('Поделиться'), 3000);
        }
    }
    useEffect(() => {
        if (isLoading || cards.length === 0) return;
        setIsLoading(true);
        const fetchObjectData = async (page = currentPage) => {
            try {
                let nextPage = page;
                let results: any = [];

                while (true) {
                    const data = await getObjectLayout(getFieldValue("object_id"), nextPage);
                    results = [...results, ...data.results];

                    if (!data.next) break;

                    nextPage += 1;
                }

                setOffers(results.filter((item: any) => item.id != id));
            } catch (error) {
                console.error("Ошибка получения данных", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchObjectData()
    }, [cards, currentPage]);

    const visibleFilter = visibleCards.filter((item: any) => item.id != id);

    return (
        <>
            <Banner
                title={getFieldValue('title')}
                price={getFieldValue('price')}
                apartmentDeadline={getFieldValue('source_temp__apartment_deadline')}
                apartmentYear={getFieldValue('source_temp__apartment_year')}
            />
            <section className={'single'}>
                <div className='container single__container'>
                    <div className='single__product single__product_apartment'>
                        <div className='single__info'>
                            <Maps
                                imagesLayout={getFieldValue('image')}
                                coordinates={getFieldValue('coordinates')}
                                areas={getFieldValue('source_layout_temp__total_area')}
                                room={getFieldValue('source_layout_temp__room_number')}
                            />
                        </div>
                        <div className='single__right single__right_apartment'>

                            <Specialist
                                name={'Алла Сорокина'}
                                job={'Специалист по жилой недвижимости'}
                                img={'/img/special.png'}
                            />
                        </div>
                    </div>
                    <div className='single__bottom single__product'>
                        <div className='single__data single__info'>
                            <h2 className="title-block">Общая информация</h2>
                            <div className="single__prices single__prices_apartment">
                                <SingleInfo
                                    price={getFieldValue('price')}
                                    priceMeter={calculatePricePerSquareMeter(getFieldValue('price'), getFieldValue('source_layout_temp__total_area'))}
                                />
                            </div>
                            <div className="single__actions">
                                <ActionButton icon={<FavoriteButtonIcon/>} title={'Избранное'}/>
                                <ActionButton icon={<CompareButtonIcon/>} title={'Сравнить'}/>
                                <ActionButton onClick={shareHandler} title={shareText}/>
                            </div>
                            <Description text={<Attributes getFieldValue={getFieldValue}/>} title={null}/>
                        </div>
                        <div className='single__right single__sign'>
                            <FormSign/>
                        </div>
                    </div>

                </div>
            </section>

            {width > 768 && <FeedBack/>}
            {/*<Construction/>*/}
            {/*<FeedBackRound/>*/}
            {!isLoading ? visibleFilter.length > 0 && <>
                <SimilarOffer onSortChange={() => null}/>
                <div className='similar-offer__wrappper single__items'>
                    {width <= 768 ? (
                        <Swiper
                            navigation={{
                                nextEl: '.slider-button-right',
                                prevEl: '.slider-button-left',
                            }}
                            spaceBetween={20}
                            slidesPerView={1}
                        >
                            {visibleFilter.map((item: any, ind: number) => (
                                <SwiperSlide key={ind}>
                                    <Card {...item} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (

                        visibleFilter.map((item: any, ind: number) => (
                            <Card key={ind} {...item} />
                        ))

                    )}
                    <div className='similar-offer__slider-buttons'>
                        <SliderButton className="slider-button-left" arrowDirection={'left'}/>
                        <SliderButton className="slider-button-right" arrowDirection={'right'}/>
                    </div>
                </div>
                {(visibleFilter.length < offers.length) && (
                    <div className="comfort__button-full apartment__btn-more apartment__btn-more_layout">
                        <button onClick={handleLoadMore} disabled={isLoading}>
                            {isLoading ? <Loader/> : "показать ещё"}
                        </button>
                    </div>
                )}
            </> : <div className={'loader-wrapper'}><Loader/></div>}

        </>
    )
}

export default CurrentApartment
