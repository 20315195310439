import './style.scss';
import {useEffect, useRef, useState} from 'react';
import CardApartment from "../../../../entity/singel-card/components/card-apartment";


const cardInfo = {
    image: '/img/apartment.jpg',
    name: 'От застройщика без комиссии',
    price: 5150000,
    meter: 198842,
    type: 'Студия',
    square: 25.9,
    floorApartment: 2,
    fullApartment: 29,
    link: '#'
}

interface Apartment {
    square: number;
    totalPrice: number;

    [key: string]: any;
}

interface SelectedApartment {
    apartment: Apartment;
    position: { x: number; y: number };
}


export const formatToNumbers = (number: number) => {
    if (number >= 1_000 && number < 1_000_000) {
        return `${number} тыс р.`;
    }
    if (number >= 1_000_000) {
        return `${(number / 1_000_000).toFixed(1)} млн р.`;
    }
    return number.toLocaleString('ru-RU');
};


const Scheme = ({dataScheme}: any) => {
    const [selectedApartment, setSelectedApartment] = useState<SelectedApartment | null>(null);
    const cardRef = useRef<HTMLDivElement | null>(null);
    const allFloors = Array.from({length: dataScheme[0]?.['floors-total'].value}, (_, i) => i + 1).reverse();

    const uniqueSquares = Array.from(
        new Set(dataScheme.map((item: any) => item.area.value).sort((a: any, b: any) => parseFloat(a) - parseFloat(b))));

    const handlePriceClick = (e: any, apartment: Apartment) => {
        const rect = (e.target as HTMLElement).getBoundingClientRect();
        const buttonHeight = (e.target as HTMLElement).offsetHeight;
        setSelectedApartment({
            apartment: apartment,
            position: {
                x: rect.left + window.scrollX,
                y: rect.top + window.scrollY - buttonHeight - 15,
            },
        });
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                cardRef.current &&
                !cardRef.current.contains(event.target as Node) &&
                !(event.target as HTMLElement).classList.contains('scheme__price')
            ) {
                setSelectedApartment(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside as any);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside as any);
        };
    }, [selectedApartment]);


    const PriceDisplay = ({price, onClick}: { price: number; onClick: (e: any) => void }) => {
        return (
            <div className={'scheme__price active'} onClick={onClick}>
                {formatToNumbers(price)}
            </div>
        );
    };

    return (
        <div className={'scheme'}>
            <div className="scheme__body">
                <div className="scheme__meter">
                    <div></div>
                    {uniqueSquares?.map((square, ind) => (
                        <div key={ind}>
                            <>От {Number(square).toFixed(1)} м²</>
                        </div>
                    ))}
                </div>
                <div className="scheme__options">
                    {allFloors.map((floor) => {
                        return (
                            <div key={floor} className="scheme__floor-row">
                                <div className="scheme__floor">{floor}</div>
                                {uniqueSquares.map((squareSize, idx) => {
                                    const apartment = dataScheme.find((item: any) => squareSize == item.area.value && floor == item.floor.value) || null;
                                    return (
                                        <div key={idx}>
                                            {apartment && apartment.price ? (
                                                <PriceDisplay
                                                    price={+apartment.price}
                                                    onClick={(e: any) => handlePriceClick(e, apartment)}
                                                />
                                            ) : (
                                                <div className={'scheme__price'}></div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}

                </div>
            </div>
            {selectedApartment && (
                <div
                    className={'scheme__card'}
                    ref={cardRef}
                    style={{
                        top: selectedApartment.position.y - (cardRef.current?.offsetHeight || 0) + 25,
                        left: selectedApartment.position.x,
                    }}
                >
                    <CardApartment {...selectedApartment.apartment} />
                </div>
            )}
        </div>
    );
};

export default Scheme;
