import SingleGallery from '../../entity/singel-card/components/single-gallery'
import Banner from './components/Banner'
import Share from '../../entity/singel-card/components/action-button'

import './style.scss'
import Price from './components/Price'
import Specialist from './components/Specialist'
import Filters from './components/Filters'
import Description from './components/Description'
import FormSign from './components/FormSign'
import Apartments from './components/Apartments'
import SingleInfo from './components/SingleInfo'
import {useEffect, useRef, useState} from 'react'
import {getObject, getObjectLayout} from '../../shared/api/endpoints'
import {useParams} from "react-router-dom";
import FeedBackRound from "../current-appartament/modules/feedback-round/feedback-round";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";

export type typeObject = {
    constant: boolean,
    current_related_field: undefined,
    data: { value: string, text_value?: string },
    field_type: string,
    id: number,
    is_dynamic: boolean,
    object_type: string,
    options: string[],
    position: number,
    real_estate_type: string,
    required: boolean,
    required_funnels: string[],
    source: string,
    static_values: [],
    title: string
}

const SingleCard = ({}) => {
    const {id} = useParams();
    const {width} = useWindowDimensions();
    const [filteredCards, setFilteredCards] = useState([]);
    const [schemeCards, setSchemeCards] = useState([])
    const [shareText, setShareText] = useState('Поделиться');
    const [dataSingle, setDataSingle] = useState<typeObject[]>([]);
    const [dataLayout, setDataLayout] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const refBody = useRef<HTMLDivElement | null>(null)
    const getFieldValue = (source: string) => {
        return dataSingle?.find((item) => item.source === source)?.data?.value;
    };
    const filterCards = Object.values(filteredCards);
    const schemeItems = Object.values(schemeCards)
    useEffect(() => {
        if (isLoading) return;
        setIsLoading(true);
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const {object_fields} = await getObject({id})
                setDataSingle(object_fields)
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        }
        fetchData()

    }, [])

    const fetchMoreData = async (page = currentPage) => {
        if (isLoading) return;
        try {
            const data = await getObjectLayout(id, page);
            if (data.next) {
                setIsLoading(true);
                //@ts-ignore
                setDataLayout((prev) => [...prev, ...data.results]);
                setCurrentPage((prev) =>  prev + 1);
            } else{
                //@ts-ignore
                setDataLayout((prev) => [...prev, ...data.results]);
            }

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const shareHandler = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            setShareText('Скопировано!');
        } catch (error) {
            setShareText('Ошибка копирования');
        } finally {
            setTimeout(() => setShareText('Поделиться'), 3000);
        }
    }
    return (
        <>
            <Banner
                title={getFieldValue('title')}
                price={getFieldValue('price')}
                apartmentDeadline={getFieldValue('source_temp__apartment_deadline')}
                apartmentYear={getFieldValue('source_temp__apartment_year')}
                loading={isLoading}
            />
            <section className={'single'}>
                <div className='container single__container'>
                    <Share onClick={shareHandler} title={shareText}/>
                    <div className='single__product'>
                        <div className='single__info'>
                            <SingleGallery loading={isLoading} images={getFieldValue('source_temp__photo')}/>
                        </div>
                        <div className='single__right'>
                            <Price
                                price={getFieldValue('price')}
                                address={getFieldValue('address')}
                                loading={isLoading}
                                meter={getFieldValue('source_temp__rooms_area')}
                                metro={getFieldValue('time_to_subway')}
                            />
                            <Specialist
                                name={'Алла Сорокина'}
                                job={'Специалист по жилой недвижимости'}
                                img={'/img/special.png'}
                            />
                        </div>
                    </div>
                    <div className='single__bottom single__product'>
                        <div className='single__data single__info'>
                            <SingleInfo
                                meter={getFieldValue('source_temp__rooms_area')}
                                floorFull={getFieldValue('source_temp__rooms_floor')}
                                floorChecked={getFieldValue('source_temp__rooms_total_floors',)}
                                year={getFieldValue('source_temp__apartment_year')}/>
                            <Description text={getFieldValue('description')}/>
                        </div>
                        <div className='single__right single__sign'>
                            <FormSign/>
                        </div>
                    </div>
                    <Filters ref={refBody} setSchemeCards={setSchemeCards} setFilteredCards={setFilteredCards} filteredCards={filteredCards}/>
                    {width > 768 && <FeedBackRound/>}
                    {<Apartments schemeCards={schemeItems} ref={refBody} currentPage={currentPage} dataLayout={dataLayout} cards={filterCards} loadMore={fetchMoreData}
                                 setFilteredCards={setFilteredCards} loading={isLoading}/>}
                </div>
            </section>
        </>
    )
}

export default SingleCard