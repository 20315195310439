import './style.css'
import ComfortItem from '../../../../entity/main-page/components/comfort-item'
import {useEffect, useRef, useState} from 'react'
import {getConstruction, getObjects} from "../../../../shared/api/endpoints";
import Loader from "../../../../entity/main-page/components/loader";
import Skeleton from "../../../../entity/singel-card/components/skeleton";

interface ComfortItemData {
    title: string
    price: string
    metro: string
    image: string
    status: string,
    id: number,
    visible: boolean
}


const Comfort = () => {
    const [visibleItems, setVisibleItems] = useState<ComfortItemData[]>([])
    const comfortSectionRef = useRef<HTMLDivElement>(null);
    const btnRefMore = useRef<HTMLDivElement>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [cards, setCards] = useState<ComfortItemData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchObjects = async (page: number) => {
        setIsLoading(true);
        try {
            const {results, total_pages, current_page_number} = await getConstruction(page);

            const newPlaces = results?.objects?.reduce((acc: any, {fields, image, ...other}: any) => {
                let newItem = {...other};
                fields.forEach(({source, value}: any) => (newItem[source] = value));
                return [...acc, {...newItem, image: `https://novactive.homes${image}`}];
            }, []);

            setCards((prevCards) => [...prevCards, ...newPlaces]);
            setCurrentPage(current_page_number);
            setMaxPage(total_pages);
        } catch (err) {
            console.error("Ошибка при загрузке объектов:", err);
        }finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchObjects(1);
    }, []);

    useEffect(() => {
        if (cards.length > 0) {
            const initialItems = cards.map((item: any, index) => ({
                ...item,
                visible:  index < 8,
            }));
            setVisibleItems(initialItems);
        }
    }, [cards]);

    const moreObjectsHandler = () => {
        const nextPageStart = visibleItems.filter((item) => item.visible).length;
        const nextPageEnd = nextPageStart + 8;
        if (nextPageEnd <= cards.length) {
            setVisibleItems((prevItems) =>
                prevItems.map((item, index) => ({
                    ...item,
                    visible: index < nextPageEnd,
                }))
            );
        } else if (currentPage < maxPage) {
            fetchObjects(currentPage + 1);
        }

    }

    return (
        <section className='comfort' id='comfort' ref={comfortSectionRef}>
            <div className='container'>
                <div className='comfort-body'>
                    <div className='comfort__header'>
                        <h2 className='comfort-title title'>
                            Комфортная жизнь
                            <span>в новой квартире </span>
                        </h2>
                    </div>
                    <div className='comfort-list'>

                        {!isLoading ? visibleItems.map((item, index) => (item.visible ?
                            <ComfortItem key={index} {...item} /> : null)) : <Skeleton height={'250px'}/>}
                    </div>
                    <div className="comfort__button-full" ref={btnRefMore}>
                        {currentPage < maxPage ? (
                            <button onClick={moreObjectsHandler} disabled={isLoading}>
                                {isLoading ? <Loader/> : "показать ещё"}
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Comfort
