import {http} from './instanse'

export const getToken = async (auth: { email_canonical: string, password: string }) => {
    const {data} = await http.post(`https://novactive.homes/api/auth/jwt/token/`, auth)
    return data
}

export const getObjects = async (page: number) => {
    const {data} = await http.get(`https://novactive.homes/api/object/?page=${page}`)
    return data
}

export const getObject = async ({id}: { id?: string }) => {
    const {data} = await http.get(`https://novactive.homes/api/object/${id}`)
    return data
}

export const getObjectLayout = async (id: any, page?: any) => {
    const {data} = await http.get(`https://novactive.homes/api/object-layout/?object=${id}&page=${page}`)
    return data
}

export const getCardsObjectLayout = async (id: any) => {
    const {data} = await http.get(`https://novactive.homes/api/object-layout/?id=${id}`)
    return data
}

export const getConstruction = async (page: number) => {
    const {data} = await http.get(`https://novactive.homes/api/object/nmarket/?page=${page}`)
    return data
}


export const getFilterCard= async (id: any, query: string) => {
    const {data} = await http.get(`https://novactive.homes/api/object-layout/list_lay/?object=${id}&${query}`)
    return data
}

export const getOffersCard= async (id: any, page: any, price_in: string) => {
    const {data} = await http.get(`https://novactive.homes/api/object-layout/?object=${id}&page=${page}&price_in=${price_in}`)
    return data
}